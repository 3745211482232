import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { BASE_URL } from '../app/api';
import { token } from '../utils/localStorage';

const createRequest = (url) => ({ url });

export const rolesApi = createApi({
  reducerPath: 'rolesApi',
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  endpoints: (builder) => ({
    getRoleGroups: builder.query({
      query: () => ({
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        url: `/rolegroups`,
        method: 'GET',
      }),
    }),
    getRolesByGroupId: builder.query({
      query: (id) => ({
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        url: `/roles/group/${id}`,
        method: 'GET',
      }),
    }),
    getUserRolesByUserId: builder.query({
      query: (id) => ({
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        url: `/roles/user-role/${id}`,
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useGetRoleGroupsQuery,
  useGetRolesByGroupIdQuery,
  useGetUserRolesByUserIdQuery,
} = rolesApi;
