import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { BASE_URL } from '../app/api';
import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from '../app/fetch';

const initialState = {
  industries: [],
  industryParents: [],
  error: ''
};

// GET REQUESTS
export const fetchIndustries = createAsyncThunk(
  'fetchIndustries',
  async (body) => {
    const result = await getRequest(`${BASE_URL}/GetAllIndustries`, 'get');
    return result;
  }
);
export const fetchIndustryParents = createAsyncThunk(
  'fetchIndustryParents',
  async (body) => {
    const result = await getRequest(`${BASE_URL}/GetIndustryParents`, 'get');
    return result;
  }
);
// POST
export const createIndustry = createAsyncThunk(
  'createIndustry',
  async (body) => {
    const result = await postRequest(`${BASE_URL}/AddIndustry`, body);
    console.log(result);
    return result;
  }
);

// export const fetchOrganizationById = createAsyncThunk("fetchOrganizationById", async (body) => {
//     const result = await getRequest(`${BASE_URL}/GetOrganizationById`, "get");
//     return result;
//   });

// // POST REQUESTS

// export const createOrganization = createAsyncThunk("createOrganization", async (body) => {
//   const result = await postRequest(`${BASE_URL}/AddOrganization`, body);
//   return result;
// });

// // PUT REQUESTS

// export const updateOrganization = createAsyncThunk("updateOrganization", async (body) => {
//   const result = await putRequest(`${BASE_URL}/UpdateOrganization`, "delete");
//   return result;
// });

const industriesReducer = createSlice({
  name: 'industries',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchIndustries.fulfilled]: (state, action) => {
      const a = action.payload;
      state.industries = a;
      return state;
    },
    [fetchIndustryParents.fulfilled]: (state, action) => {
      const a = action.payload;
      state.industryParents = a;
      return state;
    },
    [createIndustry.fulfilled]: (state, action) => {
      if (action.payload) {
        state.industries.push(action.payload);
      }
    },
    [createIndustry.rejected]: (state, action) => {
      state.error = 'Something went wrong..'
    },
    // [fetchOrganizationById.fulfilled]: (state, action) => {
    //     return state.organizationParents = action.payload;
    //   },
    // [createOrganization.fulfilled]: (state, action) => {
    //   if (action.payload) {
    //     state.organization.push(action.payload);
    //   }
    // },
    // [updateOrganization.fulfilled]: (state, action) => {},
    // [fetchOrganizationById.fulfilled]: (state, action) => {},
  },
});

export default industriesReducer.reducer;
