import { configureStore } from '@reduxjs/toolkit';
import tabsReducer from '../reducers/tabsReducer';
import { rolesApi } from '../services/roles-api';
import { userApi } from '../services/users-api';
import organizationReducer from '../reducers/organizationsReducer';
import indusriesReducer from '../reducers/indusriesReducer';
import typeReducer from '../reducers/typeReducer';
import { industriesApi } from '../services/industries';

export default configureStore({
  reducer: {
    [userApi.reducerPath]: userApi.reducer,
    [rolesApi.reducerPath]: rolesApi.reducer,
    [industriesApi.reducerPath]: industriesApi.reducer,
    tabs: tabsReducer,
    organizations: organizationReducer,
    industries: indusriesReducer,
    type: typeReducer,
  },
});
