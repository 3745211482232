import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { BASE_URL } from '../app/api';
import { token } from '../utils/localStorage';


export const industriesApi = createApi({
  reducerPath: 'industriesApi',
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  endpoints: (builder) => ({
    getIndustryById: builder.query({
      query: (body) => ({
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        url: `/GetIndustryById`,
        method: 'GET',
        body,
      }),
    }),
  }),
});

export const {
  useGetIndustryByIdQuery
} = industriesApi;
