import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { BASE_URL } from '../app/api';
import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
  getDataByBody
} from '../app/fetch';

const initialState = {
  singleOrganization: [],
  organization: [],
  organizationParents: [],
  organizationById: [],
  GetOrganizationById: [],
  GetOrganizations: [],
  UpdateOrganization: [],
};

// GET REQUESTS
export const fetchOrganizations = createAsyncThunk(
  'fetchOrganizations',
  async (body) => {
    const result = await getRequest(`${BASE_URL}/GetAllOrganizations`, 'get');
    return result;
  }
);
// export const fetchOrganizationById = createAsyncThunk(
//   'fetchOrganizationById',
//   async (body) => {
//     const result = await getRequest(
//       `${BASE_URL}/GetOrganizationById`,
//       body,
//       'get'
//     );
//     console.log(result);
//     return result;
//   }
// );
export const fetchOrganizationParents = createAsyncThunk(
  'fetchOrganizationParents',
  async () => {
    const result = await getRequest(
      `${BASE_URL}/GetOrganizationParents`,
      'get'
    );
    return result;
  }
);
export const fetchOrganization = createAsyncThunk(
  'fetchOrganization',
  async (body) => {
    const result = await getRequest(
      `${BASE_URL}/GetOrganizations`,
      'get',
      body
    );
    return result;
  }
);

//   export const fetchOrganizationParents = createAsyncThunk("fetchOrganizationParents", async (body) => {
//     const result = await getRequest(`${BASE_URL}/GetOrganizationParents`, "get");
//     return result;
//   });

// POST REQUESTS

export const GetOrganizations = createAsyncThunk(
  'GetOrganizations',
  async (body) => {
    const result = await getDataByBody(`${BASE_URL}/GetOrganizations`, body);
    return result;
  }
);
export const GetOrganizationById = createAsyncThunk(
  'GetOrganizationById',
  async (body) => {
    const result = await postRequest(`${BASE_URL}/GetOrganizationById`, body);
    return result;
  }
);
export const UpdateOrganization = createAsyncThunk(
  'UpdateOrganization',
  async (body) => {
    const result = await putRequest(`${BASE_URL}/UpdateOrganization`, body);
    return result;
  }
);
export const createOrganization = createAsyncThunk(
  'createOrganization',
  async (body) => {
    const result = await postRequest(`${BASE_URL}/AddOrganization`, body);
    return result;
  }
);

// PUT REQUESTS

// export const updateOrganization = createAsyncThunk(
//   'updateOrganization',
//   async (body) => {
//     const result = await putRequest(
//       `${BASE_URL}/UpdateOrganization`,
//       body,
//       'put'
//     );
//     return result;
//   }
// );

const organizationReducers = createSlice({
  name: 'organizations',
  initialState,
  reducers: {},
  extraReducers: {
    // [fetchOrganizations.fulfilled]: (state, action) => {
    //   const a = action.payload;
    //   state.organization = a;
    //   return state;
    // },
    // [fetchOrganizationParents.fulfilled]: (state, action) => {
    //   const a = action.payload;
    //   state.organizationParents = a;
    //   return state;
    // },
    // [fetchOrganization.fulfilled]: (state, action) => {
    //   const a = action.payload;
    //   state.singleOrganization = a;
    //   return state;
    // },
    // [fetchOrganizationById.fulfilled]: (state, action) => {
    //   const a = action.payload;
    //   state.organizationById = a;
    //   return state;
    // },
    [createOrganization.fulfilled]: (state, action) => {
      if (action.payload) {
        state.organization.push(action.payload);
      }
    },
    [GetOrganizations.fulfilled]: (state, action) => {
      const a = action.payload;
      state.GetOrganizations = a;
      return state;
    },
    [GetOrganizationById.fulfilled]: (state, action) => {
      const a = action.payload;
      state.GetOrganizationById = a;
      return state;
    },
    [UpdateOrganization.fulfilled]: (state, action) => {
      const a = action.payload;
      state.UpdateOrganization = a;
      return state;
    },
    // [updateOrganization.fulfilled]: (state, action) => {},
    // [fetchOrganizationById.fulfilled]: (state, action) => {},
  },
});

export default organizationReducers.reducer;
