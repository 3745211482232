import React, { lazy, useEffect } from "react";
import { Provider } from "react-redux";
import store from "./app/store";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { useHistory } from "react-router-dom";
import AccessibleNavigationAnnouncer from "./components/AccessibleNavigationAnnouncer";
import "antd/dist/antd.css";
import "./App.css";

const Layout = lazy(() => import("./containers/Layout"));
const UserList = lazy(() => import("./pages/UserList/UserList"));
const Login = lazy(() => import("./pages/Login"));
const CreateAccount = lazy(() => import("./pages/CreateAccount"));
const ForgotPassword = lazy(() => import("./pages/ForgotPassword"));
const ResetPassword = lazy(() => import("./pages/ResetPassword"));
const CreateOrganization = lazy(() =>
  import("./pages/Organizations/CreateOrganization/CreateOrganization")
);

function App() {
  const token = localStorage.getItem("token");
  const user = localStorage.getItem("user");
  const expireToken = 1000 * 3600;
  const history = useHistory();
  useEffect(() => {
    setTimeout(() => {
      if (token) {
        localStorage.removeItem("token");
        history.push("/");
          window.location.reload();
      }
    }, expireToken);
  }, []);
  const queryClient = new QueryClient();
  if (!token || token == "undefined") {
    return (
      <Router>
        <AccessibleNavigationAnnouncer />
        <QueryClientProvider client={queryClient}>
          <Provider store={store}>
            <Switch>
              <>
                <Route exact path="/" component={Login} />
                <Route exact path="/create-account" component={CreateAccount} />
                <Route
                  exact
                  path="/forgot-password"
                  component={ForgotPassword}
                />
                <Route
                  exact
                  path="/reset-password/:token"
                  component={ResetPassword}
                />
                {/* <Route component={Login} /> */}
              </>
            </Switch>
          </Provider>
        </QueryClientProvider>
      </Router>
    );
  }
  return (
    <>
      <Router>
        <AccessibleNavigationAnnouncer />
        <QueryClientProvider client={queryClient}>
          <Provider store={store}>
            <Switch>
              <>
                {/* <Route path="/" component={Dashboard} /> */}
                <Route path="/" component={Layout} />
                {/* <Route component={Page404} /> */}
              </>
            </Switch>
          </Provider>
        </QueryClientProvider>
      </Router>
    </>
  );
}

export default App;
