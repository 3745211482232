import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { BASE_URL } from '../app/api';
import { deleteRequest, getRequest, postRequest } from '../app/fetch';

const initialState = [];

// GET REQUESTS
export const fetchAllTabs = createAsyncThunk('fetchAllTabs', async (body) => {
  const result = await getRequest(`${BASE_URL}/GetTabs`, 'get');
  return result;
});

// POST REQUESTS

export const createTab = createAsyncThunk('createTab', async (body) => {
  const result = await postRequest(`${BASE_URL}/AddTab`, body);
  console.log(result);
  return result;
});

// DELETE REQUESTS

export const deleteTab = createAsyncThunk('deleteTab', async (body) => {
  const result = await deleteRequest(`${BASE_URL}/DeleteTab`, 'delete');
  return result;
});

const tabsReducer = createSlice({
  name: 'tabs',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchAllTabs.fulfilled]: (state, action) => {
      return action.payload;
    },
    [createTab.fulfilled]: (state, action) => {
      if (action.payload) {
        state.push(action.payload);
      }
    },
    [deleteTab.fulfilled]: (state, action) => {
      const removeTab = state.filter(
        (item) => item._id != action.payload.TabId
      );
      return removeTab;
    },
  },
});

export default tabsReducer.reducer;
