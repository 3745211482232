import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { BASE_URL } from '../app/api';
import { token } from '../utils/localStorage';

const createRequest = (url) => ({ url });

export const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  endpoints: (builder) => ({
    getUsers: builder.query({
      query: () => ({
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        url: `/users/`,
        method: 'GET',
      }),
    }),
    getUserById: builder.query({
      query: (id) => ({
        headers: {
          'Content-Type': 'application/json',
        },
        url: `/users/${id}`,
        method: 'GET',
      }),
    }),
    createUser: builder.mutation({
      query: (user) => ({
        headers: {
          'Content-Type': 'application/json',
        },
        url: `/users/register`,
        method: 'POST',
        body: user,
      }),
    }),
    loginUser: builder.mutation({
      query: (user) => ({
        headers: {
          'Content-Type': 'application/json',
        },
        url: `/users/login`,
        method: 'POST',
        body: user,
      }),
    }),
    forgotPassword: builder.mutation({
      query: (body) => ({
        headers: {
          'Content-Type': 'application/json',
        },
        url: `/users/reset-password`,
        method: 'POST',
        body: body,
      }),
    }),
    newPassword: builder.mutation({
      query: (body) => ({
        headers: {
          'Content-Type': 'application/json',
        },
        url: `/users/new-password`,
        method: 'POST',
        body: body,
      }),
    }),
    postUserRole: builder.mutation({
      query: (body) => ({
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        url: `/user-roles/${body.userId}`,
        method: 'POST',
        body: body,
      }),
    }),
    getUserRoles: builder.query({
      query: (body) => ({
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        url: `/user-roles/${body}`,
        method: 'GET',
      }),
    }),
    getUsersCount: builder.query({
      query: () => ({
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        url: `/users/get/count`,
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useGetUsersQuery,
  useCreateUserMutation,
  useLoginUserMutation,
  useForgotPasswordMutation,
  useNewPasswordMutation,
  useGetUserByIdQuery,
  useGetUserRolesQuery,
  useGetUsersCountQuery,
} = userApi;
