import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { BASE_URL } from '../app/api';
import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from '../app/fetch';

const initialState = {
  type: [],
};

// GET REQUESTS
export const fetchType = createAsyncThunk('fetchType', async (body) => {
  const result = await getRequest(`${BASE_URL}/GetTypes`, 'get');
  return result;
});
const typeReducer = createSlice({
  name: 'type',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchType.fulfilled]: (state, action) => {
      const a = action.payload;
      state.type = a;
      return state;
    },
  },
});

export default typeReducer.reducer;
